import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { OrgContextDto, SessionDto } from '@data-access/bulk-operations-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import { USER_SESSION } from '../core.module';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  companyId: string;
  private _userCompany: BehaviorSubject<OrgContextDto> = new BehaviorSubject(null);
  private userCompany$ = this._userCompany.asObservable();

  constructor(
    @Optional() @Inject(USER_SESSION) private userSession: SessionDto,
    private router: Router,
  ) {
    this.initUserCompany();
  }

  getSession(): Observable<SessionDto> {
    return new BehaviorSubject<SessionDto>(this.userSession).asObservable();
  }

  getUserCompany() {
    return this.userCompany$.pipe(skipWhile((companyData) => !companyData));
  }

  private initUserCompany() {
    const urlPathParam = window.location.pathname?.split('/')?.[1];

    if (/^[1-9][0-9]*$/.test(urlPathParam)) {
      this.companyId = urlPathParam;
    } else {
      console.error(`OdpOgId not found in path ${window.location.href}`);

      return this.router.navigate(['/unauthorized']);
    }

    const userCompanyData = this.userSession?.availableContexts?.find(
      (context: OrgContextDto) => context.id.toString() === this.companyId,
    );

    this._userCompany.next(userCompanyData);
  }
}
