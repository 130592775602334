import { Directive, HostListener, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[diTrimWhitespace]',
})
export class TrimWhitespaceDirective {
  protected readonly control = inject(NgControl);

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.control.control.setValue(value.trim());
  }
}
