<router-outlet />
<di-toaster />
<!-- Common error messages -->

<ng-template diError="required" i18n>This field is required.</ng-template>
<ng-template diError="dateFormat" i18n>Wrong date or date format.</ng-template>
<ng-template diError="minDate" let-error i18n>Minimum date is {{ error.min | date: 'shortDate' }}.</ng-template>
<ng-template diError="maxDate" let-error i18n>Maximum date is {{ error.max | date: 'shortDate' }}.</ng-template>
<ng-template diError="min" let-error i18n>Minimum value is {{ error.min }}.</ng-template>
<ng-template diError="max" let-error i18n>Maximum value is {{ error.max }}.</ng-template>
<ng-template diError="minlength" let-error i18n>Minimum value length should be {{ error.requiredLength }}.</ng-template>
<ng-template diError="maxlength" let-error i18n>Maximum value length should be {{ error.requiredLength }}.</ng-template>
<ng-template diError="dateRangeInvalid" i18n>End date can't be set before start date.</ng-template>

<!-- Specific to BO error messages -->
<ng-template diError="hasDuplicates" i18n>Duplicate value exists.</ng-template>
<ng-template diError="email" i18n>E-mail address is not valid.</ng-template>
<ng-template diError="iban" i18n>Wrong IBAN number.</ng-template>
<ng-template diError="bankAccountNumber" i18n>Wrong bank account number.</ng-template>
<ng-template diError="invalidPhoneCharacters" i18n>Invalid characters (only +, 0-9, - and space allowed).</ng-template>
<ng-template diError="plusAsFirstCharacter" i18n>Plus sign may only be used as the first character.</ng-template>
<ng-template diError="beginWithPlusOrDigit" i18n>Must begin with + or a digit.</ng-template>
<ng-template diError="secondCharacterDigit" i18n>The second character must be a digit.</ng-template>
<ng-template diError="spaceOrDash" i18n>Use either space or dash as a separator.</ng-template>
<ng-template diError="max4Separators" i18n>Max 4 space/dash separators allowed.</ng-template>
<ng-template diError="atLeast5Digits" i18n>Must have at least 5 digits (4 if you begin with +).</ng-template>
<ng-template diError="nationalId" i18n>This is not a valid national ID.</ng-template>
<ng-template diError="dNumber" i18n>This is not a valid d-number.</ng-template>
<ng-template diError="overlappingDates" i18n>Periods can not overlap.</ng-template>
<ng-template diError="pattern" i18n>This is not a valid pattern.</ng-template>
<ng-template diError="invalidNameCharacter" i18n>Invalid character used.</ng-template>
<ng-template diError="followedByOneOtherCharacter" i18n
  >'.' character can only be followed by one other character.</ng-template
>
