import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedStringDto } from '@data-access/bulk-operations-api';

import { LocalizedStringHelper } from './localized-string.helper';

@Pipe({
  name: 'localizedString',
})
export class LocalizedStringPipe implements PipeTransform {
  transform(value: LocalizedStringDto): string {
    return LocalizedStringHelper.getLocalizedString(value);
  }
}
