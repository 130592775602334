import { SessionDto } from '@data-access/bulk-operations-api';
import { vismaSessionId } from '@data-access/bulk-operations-api';
import { ErrorSource } from '@datadog/browser-core';
import { LogsEvent, datadogLogs } from '@datadog/browser-logs';
import { NetworkLogsEventDomainContext } from '@datadog/browser-logs/src/domainContext.types';
import { RumEvent, RumEventDomainContext, datadogRum } from '@datadog/browser-rum';

import { environment } from './environments/environment';

export function initLogging() {
  if (environment.enableBrowserLogs) initBrowserLogger();
  if (environment.enableRUM) initRUM();
}

function initBrowserLogger() {
  datadogLogs.init({
    clientToken: 'pub83dc8b6abed2bc3326704d01ab3c21b1',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    forwardReports: 'all',
    forwardConsoleLogs: ['error', 'warn'],
    sessionSampleRate: 100,
    service: 'vhbo_frontend',
    beforeSend: logsEventFilter,
    env: environment.type,
  });

  datadogLogs.setGlobalContextProperty('microservice', 'vhbo');
  datadogLogs.setGlobalContextProperty('microservice_component', 'frontend');
  datadogLogs.setGlobalContextProperty('env', environment.type);
  datadogLogs.setGlobalContextProperty('referrer', document.referrer);
  datadogLogs.setGlobalContextProperty('sessionId', vismaSessionId());
}

function initRUM() {
  datadogRum.init({
    applicationId: 'ed1a8021-14f9-42a4-91d6-b87e9a3ce059',
    clientToken: 'pub83dc8b6abed2bc3326704d01ab3c21b1',
    site: 'datadoghq.eu',
    service: 'vhbo_frontend',
    // version: '1.0.0',
    traceSampleRate: 100,
    trackUserInteractions: true,
    enablePrivacyForActionName: true,
    trackResources: true,
    trackLongTasks: true,
    beforeSend: beforeRumEventSend,
    env: environment.type,
  });
}

export function setLoggingContext(odpOrgId: string, session: SessionDto) {
  const userInfo = {
    id: session?.odpUserId.toString(),
    name: session?.firstName + ' ' + session?.lastName,
    email: session?.emailAddress,
  };

  datadogLogs.setUser(userInfo);
  datadogRum.setUser(userInfo);

  const orgInfo = {
    id: odpOrgId,
    name: session?.availableContexts?.find((org) => org.id.toString() === odpOrgId)?.name,
  };

  datadogLogs.setGlobalContextProperty('org', orgInfo);
  datadogRum.setGlobalContextProperty('org', orgInfo);
}

function beforeRumEventSend(event: RumEvent, context: RumEventDomainContext): boolean {
  // Discard events that are marked as aborted
  if ('isAborted' in context && context.isAborted) {
    return false;
  }

  // Discard events that are not related to visma.net
  if (event.type === 'resource') {
    if (!new RegExp(/(?<=:\/\/)[^?]*\.visma\.net/).test(event.resource.url)) {
      return false;
    }
  }
}

function logsEventFilter(
  event: LogsEvent,
  context: NetworkLogsEventDomainContext | never,
): boolean {
  // network errors will be logged explicitly by correlation-id.interceptor so that callId can be added to the context
  return context && 'isAborted' in context
    ? !context.isAborted
    : event?.error?.['origin'] !== ErrorSource.NETWORK;
}
