import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisablingFeatureGuard, EnablingFeatureGuard } from '@feature-flag';

import { FileImportGridComponent } from './components/file-import-grid/file-import-grid.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthenticationErrorComponent } from './pages/authentication-error/authentication-error.component';
import { BetaComponent } from './pages/beta/beta.component';
import { ErrorComponent } from './pages/error/error.component';
import { PayrollPortalComponent } from './pages/payroll-portal/payroll-portal.component';
import { ServiceUnavailableComponent } from './pages/service-unavailable/service-unavailable.component';
import { SettingsGridComponent } from './pages/settings-grid/settings-grid.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [DisablingFeatureGuard],
    data: {
      flagKey: 'disable-import-service',
      redirectRoute: 'service-unavailable',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'employees' },
      {
        path: 'history',
        loadChildren: () =>
          import('./modules/history-grid/history.routes').then((m) => m.HISTORY_GRID_ROUTES),
      },
      { path: 'error', component: ErrorComponent },
      { path: 'file-imports', component: FileImportGridComponent },
      {
        path: 'settings',
        children: [
          { path: '', component: SettingsGridComponent },
          {
            path: 'company',
            loadChildren: () =>
              import('./modules/company/company.module').then((m) => m.CompanyModule),
          },
          {
            path: 'cost-units',
            loadChildren: () =>
              import('./modules/cost-units/cost-units.routes').then((m) => m.COST_UNITS_ROUTES),
          },
        ],
      },
      {
        path: 'payroll-portal',
        component: PayrollPortalComponent,
      },
      {
        path: 'employees',
        canMatch: [DisablingFeatureGuard],
        data: {
          flagKey: 'enable-employee-ic-grid',
          redirectRoute: 'employees-grid',
        },
        loadChildren: () =>
          import('./modules/employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        path: 'employees-grid',
        canMatch: [EnablingFeatureGuard],
        data: {
          flagKey: 'enable-employee-ic-grid',
          redirectRoute: 'employees',
        },
        loadChildren: () =>
          import('./modules/employees-ic-grid/employees-ic-grid.routes').then(
            (m) => m.EMPLOYEE_IC_GRID_ROUTES,
          ),
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
      },
      {
        path: 'employee',
        canMatch: [DisablingFeatureGuard],
        data: {
          flagKey: 'disable-employee-card',
          redirectRoute: '../',
        },
        loadChildren: () =>
          import('./modules/employee/employee-card/employee-card.routes').then(
            (m) => m.EMPLOYEE_CARD_ROUTES,
          ),
      },
      { path: 'beta', component: BetaComponent },
      {
        path: 'fixed-transactions',
        canMatch: [EnablingFeatureGuard],
        data: {
          flagKey: 'enable-fixed-transaction-list',
          redirectRoute: '../',
        },
        loadChildren: () =>
          import('./modules/fixed-transactions/fixed-transactions.routes').then(
            (m) => m.FIXED_TRANSACTIONS_ROUTES,
          ),
      },
      {
        path: 'item-cache',
        canMatch: [EnablingFeatureGuard],
        data: {
          flagKey: 'enable-item-cache-overview',
          redirectRoute: '../',
        },
        loadChildren: () => import('@item-cache-ui').then((m) => m.ITEM_CACHE_UI_ROUTES),
      },
    ],
  },
  { path: 'authentication-error', component: AuthenticationErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'service-unavailable',
    component: ServiceUnavailableComponent,
    canActivate: [EnablingFeatureGuard],
    data: {
      flagKey: 'disable-import-service',
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
