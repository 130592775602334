import { Pipe, PipeTransform } from '@angular/core';
import { TagDto } from '@data-access/bulk-operations-api';

@Pipe({
  name: 'tagsById',
})
export class TagsByIdPipe implements PipeTransform {
  transform(tagsIds: string[], tags: TagDto[]): TagDto[] {
    return tags?.filter((tag) => tagsIds?.includes(tag.id));
  }
}
