import { ILocalizedStringDto } from '@data-access/bulk-operations-api';

import { getLocalePreference } from '../../../localization.helper';
import { LanguagesEnum } from './languages.enum';

export class LocalizedStringHelper {
  static getLocalizedString(value: ILocalizedStringDto): string {
    return getLocalePreference() === LanguagesEnum.No ? value?.norwegian : value?.english;
  }
}
