import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { API_BASE_URL, SessionDto } from '@data-access/bulk-operations-api';
import { WS_BASE_URL, WebSocketClient } from '@data-access/bulk-operations-ws';
import { FeatureFlagService, LD_CLIENT_SIDE_ID } from '@feature-flag';
import { VudSelectModule } from '@vismaux/ngx-vud';
import { take } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { CorrelationIdInterceptor } from './interceptors/correlation-id.interceptor';

export const USER_SESSION = new InjectionToken<SessionDto>('User session data.');

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, VudSelectModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (injector: Injector) => new CorrelationIdInterceptor(injector),
      multi: true,
      deps: [Injector],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (injector: Injector) => new AuthorizationInterceptor(injector),
      multi: true,
      deps: [Injector],
    },
    { provide: API_BASE_URL, useValue: environment.dataImportApiBaseUrl },
    { provide: WS_BASE_URL, useValue: environment.wsBaseUrl },
    { provide: LD_CLIENT_SIDE_ID, useValue: environment.ldClientSideID },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  constructor(
    private featureFlagService: FeatureFlagService,
    private wsClient: WebSocketClient,
    @Optional() @SkipSelf() parentModule?: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    if (environment.dummyAuth) {
      document.cookie = 'GsId=dummy';
    }

    this.featureFlagService
      .getFlagValue('log-ws-messages-to-console')
      .pipe(take(1))
      .subscribe((isLogingEnabled) => {
        if (isLogingEnabled) {
          this.wsClient.isLoggingEnabled.set(true);
        }
      });
  }
}
