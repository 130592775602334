import { Component, OnDestroy } from '@angular/core';
import { TaskStatusEnum } from '@data-access/bulk-operations-api';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'di-grid-results-status-panel',
  templateUrl: './grid-results-status-panel.component.html',
  styleUrls: ['./grid-results-status-panel.component.scss'],
})
export class GridResultsStatusPanelComponent implements IStatusPanelAngularComp, OnDestroy {
  private params!: IStatusPanelParams;
  totalTaskCount = 0;
  failedTaskCount = 0;
  succeededTaskCount = 0;
  isProcessed = false;
  private boundUpdateCounts: () => void;
  agInit(params: IStatusPanelParams): void {
    this.params = params;
    this.isProcessed = this.params.context?.getIsProcessed();
    this.boundUpdateCounts = this.updateCounts.bind(this);

    this.params.api.addEventListener('modelUpdated', this.boundUpdateCounts);
  }
  private updateCounts(): void {
    this.isProcessed = this.params.context?.getIsProcessed();
    let totalRowCount = 0;
    let failedTaskCount = 0;
    let succeededTaskCount = 0;
    this.params.api.forEachNode((node) => {
      totalRowCount++;
      if ([TaskStatusEnum.Failed, TaskStatusEnum.PollingTimeout].includes(node.data.status)) {
        failedTaskCount++;
      }
      if ([TaskStatusEnum.Successful].includes(node.data.status)) {
        succeededTaskCount++;
      }
    });
    this.totalTaskCount = totalRowCount;
    this.failedTaskCount = failedTaskCount;
    this.succeededTaskCount = succeededTaskCount;
  }
  ngOnDestroy(): void {
    this.params.api.removeEventListener('modelUpdated', this.boundUpdateCounts);
  }
}
