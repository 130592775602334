import { Component, Input } from '@angular/core';
import { TagDto } from '@data-access/bulk-operations-api';

@Component({
  selector: 'di-tag-option',
  templateUrl: './tag-option.component.html',
  styleUrls: ['./tag-option.component.scss'],
})
export class TagOptionComponent {
  @Input() item: TagDto;
}
