import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlagService } from '@feature-flag';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, shareReplay } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserSessionService } from '../../core/services/user-session.service';

@Component({
  selector: 'di-settings-grid',
  templateUrl: './settings-grid.component.html',
  styleUrls: ['./settings-grid.component.scss'],
})
@UntilDestroy()
export class SettingsGridComponent implements OnInit {
  orgId: number;
  isTagsDisabled$: Observable<boolean>;

  constructor(
    private userSessionService: UserSessionService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userSessionService
      .getUserCompany()
      .pipe(untilDestroyed(this))
      .subscribe((userCompany) => (this.orgId = userCompany.id));
    this.isTagsDisabled$ = this.featureFlagService
      .getFlagValue('disable-org-tags')
      .pipe(shareReplay());
  }

  navigateToExternal(route: string, isPayrollUrl?: boolean): void {
    const url = isPayrollUrl ? this.createPayrollUrl(route) : this.createEmpmanUrl(route);
    window.open(url, '_blank');
  }

  navigateTo(route: string): void {
    this.router.navigateByUrl(route);
  }

  private createPayrollUrl(route: string): string {
    return environment.payrollUiUrl + `?orgId=${this.orgId}` + route;
  }

  private createEmpmanUrl(route: string): string {
    return environment.vismaEmpmanUrl + `#/${this.orgId}` + route;
  }
}
