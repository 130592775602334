import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@feature-flag';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WalkmeService {
  window: Window;

  constructor(private featureFlagService: FeatureFlagService) {
    this.featureFlagService
      .getFlagValue('disable-walkme')
      .pipe(take(1))
      .subscribe((value) => {
        if (!value) {
          this.init();
        }
      });
  }

  private init(): void {
    const walkmeScript = document.createElement('script');
    walkmeScript.async = true;
    walkmeScript.setAttribute('crossOrigin', '');
    walkmeScript.src = environment.walkmeSrc;
    walkmeScript.integrity = environment.walkmeIntegrity;
    document.head.appendChild(walkmeScript);
    window._walkmeConfig = { smartLoad: true };
  }

  open() {
    if (!window.WalkMePlayerAPI?.isMenuOpen()) {
      window.WalkMePlayerAPI?.toggleMenu();
    }
  }
}

declare global {
  interface Window {
    WalkMePlayerAPI?: {
      isMenuOpen: () => boolean;
      toggleMenu: () => void;
    };
    _walkmeConfig: {
      smartLoad: boolean;
    };
  }
}
