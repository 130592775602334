import {
  BasePayInputTypeV3EnumDto,
  IcLookupPositionPayDto,
} from '@data-access/bulk-operations-api';

export function calculateSalariesUsingIcLookupPositionPayDto(
  pay: IcLookupPositionPayDto,
  normalWeeklyHours: string | undefined,
): IcLookupPositionPayDto {
  const hourlyWage = parseFloat(pay.hourlyWage ?? '');
  const fullTimeMonthlySalary = parseFloat(pay.fullTimeMonthlySalary ?? '');
  const fullTimeYearlySalary = parseFloat(pay.fullTimeYearlySalary ?? '');
  const workHours = parseFloat(normalWeeklyHours ?? '');

  if (!pay.inputType || !workHours) {
    return pay;
  }

  const computedSalaries = calculateSalariesInternal(
    pay.inputType,
    hourlyWage,
    fullTimeMonthlySalary,
    fullTimeYearlySalary,
    workHours,
  );

  return new IcLookupPositionPayDto({
    ...pay,
    hourlyWage: toFormatedString(computedSalaries.hourlyWage),
    fullTimeMonthlySalary: toFormatedString(computedSalaries.fullTimeMonthlySalary),
    fullTimeYearlySalary: toFormatedString(computedSalaries.fullTimeYearlySalary),
  });
}

function calculateSalariesInternal(
  inputType: BasePayInputTypeV3EnumDto,
  hourlyWage: number,
  fullTimeMonthlySalary: number,
  fullTimeYearlySalary: number,
  workHours: number,
): ComputedSalaries {
  switch (inputType) {
    case BasePayInputTypeV3EnumDto.Hourly:
      if (isNaN(hourlyWage)) break;

      // `hourlyWage * (WTA.normalWeeklyHours * 52)`
      fullTimeYearlySalary = hourlyWage * (workHours * 52);
      // `hourlyWage * (WTA.normalWeeklyHours * 52 / 12)`
      fullTimeMonthlySalary = hourlyWage * ((workHours * 52) / 12);
      break;
    case BasePayInputTypeV3EnumDto.FullTimeMonthly:
      if (isNaN(fullTimeMonthlySalary)) break;

      // `fullTimeMonthlySalary * 12`
      fullTimeYearlySalary = fullTimeMonthlySalary * 12;
      // `(fullTimeMonthlySalary * 12) / (WTA.normalWeeklyHours * 52)`
      hourlyWage = (fullTimeMonthlySalary * 12) / (workHours * 52);
      break;
    case BasePayInputTypeV3EnumDto.FullTimeYearly:
      if (isNaN(fullTimeYearlySalary)) break;

      // `fullTimeYearlySalary / 12`
      fullTimeMonthlySalary = fullTimeYearlySalary / 12;
      // `fullTimeYearlySalary / (WTA.normalWeeklyHours * 52)`
      hourlyWage = fullTimeYearlySalary / (workHours * 52);
      break;
  }

  return {
    inputType: inputType,
    hourlyWage: hourlyWage,
    fullTimeMonthlySalary: fullTimeMonthlySalary,
    fullTimeYearlySalary: fullTimeYearlySalary,
  };
}

function toFormatedString(input: number | undefined): string | undefined {
  return input && !isNaN(input) ? input.toFixed(2).replace(/\.00$/, '') : undefined;
}

type ComputedSalaries = {
  inputType: BasePayInputTypeV3EnumDto;
  hourlyWage: number;
  fullTimeMonthlySalary: number;
  fullTimeYearlySalary: number;
};
