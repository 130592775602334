export type CacheStatusModel = {
  retrievalTime?: Date;
  state: CacheRefreshStateEnum;
};

export enum CacheRefreshStateEnum {
  None,
  InProgress,
  Finished,
}
