import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import { MenuStep } from '#shared/components/left-menu/menu-stepper/step.model';

@Injectable({
  providedIn: 'root',
})
export class LeftMenuService {
  previousStep: number;
  stepperProperties: MenuStep[] = [];
  private _stepperPropertiesSubject: BehaviorSubject<MenuStep[]> = new BehaviorSubject<MenuStep[]>(
    this.stepperProperties,
  );
  private _showBackButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private selectEmployeesStep = $localize`Select employees`;
  private reviewStep = $localize`Review`;
  private resultsStep = $localize`Results`;
  private _menuCollapsedSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _iconMenuSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showSideMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSideMenu$ = this._showSideMenuSubject.asObservable();

  constructor(private router: Router) {
    this.trackRouterChanges();
  }

  clearStepper() {
    this.stepperProperties = [];
    this._stepperPropertiesSubject.next(this.stepperProperties);
  }

  getStepperProperties(): Observable<MenuStep[]> {
    return this._stepperPropertiesSubject
      .asObservable()
      .pipe(skipWhile((data) => data.length === 0));
  }

  getBackButtonStatus(): Observable<boolean> {
    return this._showBackButtonSubject.asObservable();
  }

  getIsMenuCollapsed(): Observable<boolean> {
    return this._menuCollapsedSubj.asObservable();
  }

  getIsIconMenu(): Observable<boolean> {
    return this._iconMenuSubj.asObservable();
  }

  toggleMenuCollapse(value: boolean): void {
    this._menuCollapsedSubj.next(value);
  }

  setIsIconMenu(value: boolean): void {
    this._iconMenuSubj.next(value);
  }

  setStepperPropertiesForSalaryUpdate(): void {
    const secondStepTitle = $localize`Edit salaries`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForEndingFixedTransactions(): void {
    const secondStepTitle = $localize`Set transactions to end`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForEditFixedTransactions(): void {
    const secondStepTitle = $localize`Edit transactions`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForTimeAgreements(): void {
    const secondStepTitle = $localize`Update time agreements`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForTagsEdit(): void {
    const secondStepTitle = $localize`Edit tags`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForBulkPositionEnd(): void {
    const secondStepTitle = $localize`End position data`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForBulkUserCreation(): void {
    const secondStepTitle = $localize`Select email address`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForBulkChangeManagers(): void {
    const secondStepTitle = $localize`Select managers`;
    this.setInitialStepperProps(secondStepTitle);
  }

  setStepperPropertiesForFileImport(): void {
    this.previousStep = 0;
    this.stepperProperties = [
      {
        title: $localize`Select file`,
        active: true,
        passed: false,
      },
      { title: this.reviewStep, active: false, passed: false },
      { title: this.resultsStep, active: false, passed: false },
    ];

    this._stepperPropertiesSubject.next(this.stepperProperties);
  }

  changeStep(stepNumber: number, isGoingBack?: boolean): void {
    if (stepNumber > this.stepperProperties.length) {
      return;
    }

    if (this.previousStep === this.stepperProperties.length && !isGoingBack) {
      this.previousStep = 0;
    }

    if (this.previousStep > stepNumber) {
      this.stepperProperties[this.previousStep - 1].active = false;
      this.stepperProperties[this.previousStep - 2].passed = false;
    } else if (stepNumber >= 2) {
      this.stepperProperties[stepNumber - 2].active = false;
      this.stepperProperties[stepNumber - 2].passed = true;
    }
    this.stepperProperties[stepNumber - 1].active = true;
    this.previousStep = stepNumber;
  }

  showBackButton(value: boolean) {
    this._showBackButtonSubject.next(value);
  }

  private setInitialStepperProps(stepTitle: string): void {
    this.previousStep = 0;
    this.stepperProperties = [
      {
        title: this.selectEmployeesStep,
        active: false,
        passed: true,
      },
      {
        title: stepTitle,
        active: true,
        passed: false,
      },
      { title: this.reviewStep, active: false, passed: false },
      { title: this.resultsStep, active: false, passed: false },
    ];

    this._stepperPropertiesSubject.next(this.stepperProperties);
  }

  private trackRouterChanges(): void {
    this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((ev) => {
      const navEndUrl = (ev as NavigationEnd).urlAfterRedirects.split('/');
      const isMenuForUrlHidden =
        (navEndUrl.length === 2 && this.checkIfExactRouteMatch(navEndUrl[1])) ||
        this.checkIfPartialRouteMatch(navEndUrl);
      this._showSideMenuSubject.next(!isMenuForUrlHidden);
    });
  }

  private checkIfExactRouteMatch(route: string): boolean {
    const exactRoutesWithoutSideMenu = [
      'employees',
      'employees-grid',
      'settings',
      'history',
      'fixed-transactions',
    ];
    if (route.includes('?')) route = route.split('?')[0];
    return exactRoutesWithoutSideMenu.includes(route);
  }

  private checkIfPartialRouteMatch(routeArr: string[]): boolean {
    const partialRoutesWithoutSideMenu = ['company', 'grid', 'cost-units', 'item-cache'];
    return partialRoutesWithoutSideMenu.some((route) => routeArr.includes(route));
  }
}
